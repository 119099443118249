import { Dialog, DialogContent, DialogTitle, Rating } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { IconContainerProps } from "@mui/material/Rating";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";

import { useCookies } from "react-cookie";
import { useSearchParams } from "react-router-dom";
import { SurveyResponse } from "../types/survey";
import axiosInstance from "../utils/axios-instance";

export const StyledRating = styled(Rating)(({ theme }) => ({
  "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
    color: theme.palette.action.disabled,
  },
}));

export const customIcons: {
  [index: string]: {
    icon: React.ReactElement<unknown>;
    label: string;
  };
} = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon color="error" />,
    label: "Very Dissatisfied",
  },
  2: {
    icon: <SentimentDissatisfiedIcon color="error" />,
    label: "Dissatisfied",
  },
  3: {
    icon: <SentimentSatisfiedIcon color="warning" />,
    label: "Neutral",
  },
  4: {
    icon: <SentimentSatisfiedAltIcon color="success" />,
    label: "Satisfied",
  },
  5: {
    icon: <SentimentVerySatisfiedIcon color="success" />,
    label: "Very Satisfied",
  },
};

export const IconContainer = (props: IconContainerProps) => {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
};

export const NPS = () => {
  const [cookies, setCookie] = useCookies(["novProject", "novProjectUUID", "novProjectSurveyId"]);
  const [searchParams] = useSearchParams();
  const defaultOpen = cookies.novProject?.["responsed"] || false;
  const [open, setOpen] = useState(!defaultOpen);
  const [survey, setSurvey] = useState<SurveyResponse>({
    _id: "",
    creator_id: {
      _id: "",
      name: "",
      email: "",
      role: "",
    },
    is_active: true,
    title: "",
    description: "",
    trigger_criteria: [
      {
        hookUrl: "",
        pattern: "",
      },
    ],
    question: [
      {
        question_id: [
          {
            _id: "",
            question_display: "",
            is_require: false,
            option: {
              option_choices: [""],
              _id: "",
            },
          },
        ],
        step: "",
      },
    ],
  });

  const handleOnSurveyClose = useCallback(async () => {
    setOpen(false);
    window.parent.postMessage("closeSurvey", "*");
  }, []);

  const onSentimentClick = async (event: React.SyntheticEvent, value: number | null) => {
    const responseId = cookies.novProject["responseId"];
    const uid = cookies.novProjectUUID;

    await axiosInstance.post("/answer", {
      surveyId: survey._id,
      uid: uid,
      questionId: survey.question[0].question_id[0]._id,
      answerValue: value,
    });

    await axiosInstance.patch("/response/" + responseId, {
      responses: [{ questionId: survey.question[0].question_id[0]._id, responseValue: value }],
    });

    setCookie("novProject", { responseId: responseId, responsed: true }, { path: "/" });
    window.parent.postMessage("closeSurvey", "*");
  };

  useEffect(() => {
    const fetchData = async () => {
      const workspaceId = searchParams.get("workspace_id");
      const url = window.parent.location.href;
      const res = await axiosInstance.post<SurveyResponse>("/survey/" + workspaceId, { url });
      const surveyResponse = res.data;
      if (!cookies.novProjectUUID) {
        setCookie("novProjectUUID", crypto.randomUUID(), { path: "/" });
      }
      if (surveyResponse._id) {
        if (!surveyResponse.is_active) {
          setOpen(false);
        }
        window.parent.postMessage("openSurvey", "*");
        setSurvey(surveyResponse);
        if (!cookies.novProject) {
          const response = await axiosInstance.post("/response", {
            surveyId: surveyResponse._id,
            uid: cookies.novProjectUUID,
          });
          setCookie("novProject", { responseId: (response.data as { _id: string })["_id"] }, { path: "/" });
        }
      }
    };
    if (cookies.novProject?.["responsed"]) {
      window.parent.postMessage("closeSurvey", "*");
    } else {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog open={open} onClose={handleOnSurveyClose} id="survey-container">
      <DialogTitle>{survey.question[0].question_id[0].question_display}</DialogTitle>
      <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
        <StyledRating
          name="highlight-selected-only"
          defaultValue={2}
          IconContainerComponent={IconContainer}
          getLabelText={(value: number) => customIcons[value].label}
          highlightSelectedOnly
          onChange={onSentimentClick}
          sx={{ gap: "8px" }}
          size="large"
        />
      </DialogContent>
    </Dialog>
  );
};
